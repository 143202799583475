.buttonStyle{

background: #C5B2FD;
filter: blur(28.5px);
border-radius: 25px;
box-shadow: 0px 4px 0px rgba(0, 0, 0, 0.25);
}

.textStyle{
    font-family: 'Inter';
    color: #FFFFFF;
    font-size: 24px;

}